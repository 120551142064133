<template>
  <div class="body">
        <div class="container">
            <div class="profile" v-if="info">
                
                <div class="desc">
                    <img :src="info.avatar">
                    <div class="">
                        <strong style="display: block;">{{info.dingtalk.nickname}}</strong>
                        <strong>{{info.dingtalk.station}}</strong>
                    </div>
                    
                </div>
                <div class="company">
                    <strong class="rank" @click="toUrl('/tag')">
                        <van-icon name="info-o" color="#666666" />
                        {{tagName}}
                    </strong>
                    <span><span class="credit" style="display: inline-block;">{{info.credit}}</span>学分</span>
                </div>
                
            </div>
            <div class="profile" v-else>
                <img src="../assets/data/touxiang.png">
                <div class="desc">
                    <strong>-</strong>
                    <div class="company">-</div>
                </div>
            </div>
            <!-- <div class="separator-line full-width"></div> -->
            <div class="section-box" v-if="statistics">
                <div class="item" @click="toUrl('/task')">
                    <img src="../assets/images/finished.png">
                    <div class="item-title light-color">
                        已完成任务
                    </div>
                    <div class="item-desc">
                        {{statistics.finished}}
                    </div>
                </div>
                <div class="item" @click="toUrl('/task')">
                    <img src="../assets/images/unpassed.png">
                    <div class="item-title light-color">
                        未通过任务
                    </div>
                    <div class="item-desc">
                        {{statistics.unpassed}}
                    </div>
                </div>
                <div class="item" @click="toUrl('/task')">
                    <img src="../assets/images/progress.png">
                    <div class="item-title light-color">
                        进行中任务
                    </div>
                    <div class="item-desc">
                        {{statistics.progress}}
                    </div>
                </div>
                <div class="item" @click="toUrl('/task')">
                    <img src="../assets/images/undrawed.png">
                    <div class="item-title light-color">
                        未领取任务
                    </div>
                    <div class="item-desc">
                        {{statistics.undrawed}}
                    </div>
                </div>
                <!-- <div class="item" @click="toUrl('/record')">
                    <img src="../assets/images/xuefen.png">
                    <div class="item-title light-color">
                        累计学分
                    </div>
                    <div class="item-desc" v-if="info">
                        {{info.credit}}
                    </div>
                </div> -->
                <!-- <div class="item">
                    <img src="../assets/images/xueshi.png">
                    <div class="item-title light-color">
                        累计学时
                    </div>
                    <div class="item-desc">
                        0
                    </div>
                </div> -->
            </div>
            <div class="separator-line full-width"></div>
            <div class="section-menu">
                <div class="menu-item" @click="toUrl('/task')">
                    <!-- <img src="../assets/images/woderenwu.png"> -->
                    <van-icon name="column" size="40" :color="color" />
                    <div class="title">
                        我的任务
                    </div>
                </div>
                <div class="menu-item" @click="toUrl('/ranking')">
                    <!-- <img src="../assets/images/woderenwu.png"> -->
                    <!-- <van-icon name="chart-trending-o" /> -->
                    <van-icon name="chart-trending-o" size="40" :color="color" />
                    <div class="title">
                        我的排名
                    </div>
                </div>
                <div class="menu-item" @click="toUrl('/collect')">
                    <!-- <img src="../assets/images/woderenwu.png"> -->
                    <van-icon name="star" size="40" :color="color"/>
                    <div class="title">
                        我的收藏
                    </div>
                </div>
                <div class="menu-item" @click="toUrl('/hr/attendance/list')">
                    <!-- <img src="../assets/images/woderenwu.png"> -->
                    <van-icon name="todo-list" size="40" :color="color"/>
                    <div class="title">
                        我的考勤
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    // import { getUser } from '../api/common.js';
    import { checkLogin } from '../utils/common.js';
    import { mapState } from 'vuex';
    import { getUser, getTaskList, getTagList} from '../api/task.js';
	export default {
		data(){
			return {
				value: 20,
                info: null,
                statistics: null,
                color:'#1989fa',
                tagName: '',
			}
		},
       activated(){
           checkLogin().then(()=> {
               this.getPageData();
           })
            this.hideTab(1);
        },
        computed: mapState(['token']),
        methods:{
            getPageData(){
                Promise.all([this.getUser(), this.getTaskList()]);
            },
            getUser(){
                getUser().then(({data})=> {
                    console.log('data credit', data);
                    this.info = data;
                    if(data){
                        this.getRank(data.credit);
                    }
                })
            },
            getTaskList(){
                getTaskList().then((ret)=>{
                    // 已完成任务
                    // 进行中任务
                    // 未通过任务
                    // 未领取任务
                    console.log(ret);
                    var list = ret.data;
                    var finished = 0 ,unpassed = 0
                        ,progress = 0, undrawed = 0,
                        credit = 0;
                    for (var i = 0; i < list.length; i++) {
                        var task = list[i];
                        if(task.ts){
                            // 已领取
                            if(task.ts.status == 1){
                                finished = finished + 1;
                                credit = credit + Number(task.credit);
                            }else if(task.ts.status == 2){
                                unpassed = unpassed + 1;
                            }else{
                                progress = progress + 1;
                            }
                        }else{
                            undrawed = undrawed + 1;
                        }
                    }
                    this.statistics = {
                        finished,
                        unpassed,
                        progress,
                        undrawed,
                        credit
                    }
                });
             },
            hideTab(){
                //console.log(document.querySelector('#vantab'));
                document.querySelector('#vantab').classList.remove('tab-hide');
            },
            toUrl(url){
                this.$router.push(url);
            },
            getRank(credit){
                getTagList().then((ret)=> {
                    console.log('ret', ret.data);
                    var tags = ret.data;
                    for (let i = 0; i<tags.length; i++) {
                        var tag = tags[i];
                        var lt = tag.lt;
                        var gt = tag.gt;
                        console.log(`(!lt ||lt && ${credit}<lt)`, (!lt ||lt && credit<lt));
                        console.log(`(!gt || gt && ${credit}>= gt)`, (!gt || gt && credit>= gt));
                        if((!lt ||lt && credit<lt) && (!gt || gt && credit>= gt)){
                            this.tagName =  tag.name;
                            return;
                        }
                    }
                })
            }
        },
	}
</script>
<style scoped>
    .container{
        bottom: 50px;
    }
    .profile{
        display: flex;
        align-items: center;
        font-size: 16px;
        margin-bottom: 20px;
        justify-content: space-between;
    }
    .profile img{
        width: 8.1rem;
        padding-right: 10px;
    }
    .profile strong{
        font-size: 18px;
    }
    .section-box{
        display: flex;
        flex-wrap: wrap;
        /* justify-content: space-around; */
        margin-top: 2rem;
    }
    .item{
        width: 11.3rem;
        height: 11.3rem;
        /* background:#f7f8fa; */
        border-radius:5px;
        box-shadow: -4px 7px 9px 1px rgba(0, 0, 0, 0.1);
    }
    .item *{
        padding: 3px 15px;
    }
    .item img{
        width: 26px;
        padding-top: 10px;
    }
    .item-title{
        font-size: 1.4rem;
        color: #666;
    }
    .item-desc{
        font-size: 1.5rem;
        font-weight: bold;
    }
    .separator-line{
        height: 6px;
        background-color: #f2f2f2;
        margin: 17px 0 23px auto;
    }
    .content p{
        padding-bottom: 92px;
    }
    .section-menu{
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
        color: #333;
    }
    .menu-item{
        width: 8rem;
        text-align: center;
        margin-right: 0.2rem;
        margin-bottom: 0.8rem;
    }
    .menu-item:nth-child(4n){
        margin-right: 0rem;
    }
    .section-menu img{
        width: 32px;
    }
    .company{
        width: 120px;
        text-align: right;
        padding-right: 20px;
    }
    .desc{
        display: flex;
        align-items: center;
    }
    .credit{
        color: #ff6a26;
        font-size: 24px;
        font-weight: bold;
        display: block;
    }
    .rank{
        display: block;
        /* padding-right: 40px; */
        font-size: 20px;
    }
</style>
